import styled from 'styled-components';
import { PriceBoxStyledOV } from './PriceBoxStyled.OV';

export const PriceBoxStyledOVES = styled(PriceBoxStyledOV)`
    .aprValue,
    .aprDescription {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        color: ${({ theme }) => theme.colors.black};
    }
    .aprDescription {
        text-align: left;
    }
`;
