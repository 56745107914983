import styled from 'styled-components';
import { css } from '../../../../styles/theme';
import { PriceBoxStyled } from '../PriceBoxStyled';
import { rem } from 'polished';

export const PriceBoxStyledOVGB = styled(PriceBoxStyled)`
    background: ${({ theme }) => theme.colors.grey10};

    .catalogPrice {
        background-color: ${({ theme }) => theme.colors.white};
    }
    .priceBoxWrapper {
        background-color: ${({ theme, isStockList }) => (isStockList ? theme.colors.grey10 : theme.colors.grey20)};

        .financeLabel {
            color: ${({ theme, isStockList }) => (isStockList ? theme.colors.black : theme.colors.grey22)};

            .priceLabel {
                font-size: ${({ theme }) => theme.fontSizes.textSupBase};
                font-weight: bold;
            }
            .priceFromLabel {
                :not(.priceLabel) {
                    font-size: ${({ theme }) => theme.fontSizes.textSupBase};
                    font-weight: normal;
                }
            }
        }

        .aprValue {
            color: ${({ theme }) => theme.colors.primary};
        }

        .aprDescription {
            text-decoration: underline;
            margin-top: ${rem(5)};
        }

        .price {
            font-weight: normal;

            ${({ isStockList }) =>
                isStockList &&
                css`
                    .icon-wrapper {
                        path {
                            fill: ${({ theme }) => theme.colors.black};
                        }
                        g {
                            circle {
                                fill: ${({ theme }) => theme.colors.black};
                            }
                            path {
                                fill: ${({ theme }) => theme.colors.white};
                            }
                        }
                    }
                `}

            .cashPrice {
                > span:first-child > span {
                    font-weight: bold;
                }
            }
            .monthlyPrice {
                span {
                    font-weight: normal;
                }
            }
            .priceConjunction {
                span {
                    font-weight: normal;
                    font-size: ${rem(12)};
                    line-height: ${rem(18)};
                }
            }
            .currencyLabel__suffix--TTC,
            .currencyLabel__suffix--TTC-monthly,
            .currencyLabel__suffix--OTR {
                font-size: 0.75rem;
                font-weight: normal;
            }
        }
    }
`;
