import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { PriceLegalTextStyled } from '../PriceLegalTextStyled';
import { rem } from 'polished';

export const PriceLegalTextStyledDS = styled(PriceLegalTextStyled)`
    @media (max-width: ${breakpoints.xs}px) {
        margin-left: 0;
    }
    .legalText {
        font-family: DSAutomobiles;
        font-style: normal;
        font-weight: normal;
        font-size: ${rem('14px')};
        line-height: 1.5;
    }
`;
