import { FC } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import { rem } from 'polished';
import { PriceLegalTextProps } from '../PriceLegalText';

export const PriceLegalTextStyled: FC<PriceLegalTextProps> = styled('div')`
    color: ${(props) => props.theme.colors.grey1};
    font-style: normal;
    font-weight: normal;
    line-height: 18px;
    margin-bottom: ${rem('20px')};
    margin-left: ${rem('55px')};
    margin-right: ${rem('55px')};

    .legalText {
        font-size: ${rem('16px')};
        font-weight: normal;
        margin: ${rem('30px')} ${rem('18px')};
        @media (min-width: ${breakpoints.xs}px) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &.isTrimPage {
        margin: ${rem('30px')} ${rem('18px')};

        @media only screen and (min-width: 75em) {
            width: 92rem !important;
            margin-left: auto;
            margin-right: auto;
        }
    }
`;
