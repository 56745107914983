import React, { FC } from 'react';
import {isBrandAC, isBrandAP, isMarketGB, FINANCE_DETAIL_TOKEN, isBrandDS} from 'src/constants/main';
import { FEATURES_LIST } from 'src/context/featureSwitchApp';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';
import useTranslations from '@hooks/useTranslations';
import { useIsShowPriceExcludedVAT } from '@hooks/useIsShowPriceExcludedVAT';
import { usePriceBoxDetails } from '@hooks/usePriceDetails';
import { renderPromotionalText } from '@utils/Price.utils';
import { Icons } from '@components/Icon';
import IconButton from '@components/IconButton';
import PriceSuffix from '@components/PriceSuffix';
import { PriceBoxProps } from './index';
import { HandledComponentError } from '@components/ErrorHandling';
import AccessibleButton from '@components/Button/AccessibleButton';
import { getFormattedOfferValidityDate } from '@utils/Car.utils';
import parser from 'bbcode-to-react';
import { PriceVatExcluded } from '@components/PriceVatExcluded';
import isEmpty from 'lodash/isEmpty';

const PriceBoxTemplate: FC<PriceBoxProps> = ({
    className,
    basePrice,
    totalBasicPriceWithoutVat,
    monthlyPrice,
    catalogPrice,
    depositPrice,
    calculateSummaryData,
    isStockSlice,
    promotionalText = null,
    itemId,
    priceBoxWrapperStyle,
    amountRounding,
    childRef,
    financeDetailView,
    financeDetailText,
    apr,
    hasMonthlyPrices,
    importInputs,
    tabIndex = 0,
    index,
}) => {
    try {
        const { t } = useTranslations();
        const { formatMoney } = useMoneyFormatter();
        const isShowPriceExcludedVAT: boolean = useIsShowPriceExcludedVAT(isStockSlice);

        const {
            isCataloguePriceEqualToStorePrice,
            showFOIcon,
            showFOLink,
            isCataloguePriceFeatureSwitchEnabled,
            isFinancePriceAvailable,
        } = usePriceBoxDetails({
            catalogPrice,
            basePrice,
            financeDetailView,
            monthlyPrice,
            isStockSlice,
        });

        const showOfferValidityDate: boolean =
            useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_OFFER_VALIDITY_DATE_ENABLED) &&
            !useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_IS_FINANCE_DISABLED) &&
            hasMonthlyPrices;
        const offerValidityDate = getFormattedOfferValidityDate(importInputs);
        const offerValidityDateString =
            showOfferValidityDate && offerValidityDate ? `${t('basket.offerValidityDate')} ${offerValidityDate}` : '';

        return (
            <div className={`${className} priceBoxContainer`}>
                <div className="priceBoxContainerContentWrapper" ref={childRef?.priceBoxContainerRef}>
                    <div ref={childRef?.priceBoxCatalogRef} className="catalogPrice priceBoxCatalogRef">
                        {isCataloguePriceFeatureSwitchEnabled && !isCataloguePriceEqualToStorePrice && catalogPrice ? (
                            <>
                                <span className="catalogPriceLabel">{t('offerListItem.catalogPriceLabel')}</span>
                                <span className="catalogPriceAmount">{formatMoney(catalogPrice, true)}</span>
                                <PriceSuffix hideAsterisk />
                            </>
                        ) : null}
                    </div>
                    <div
                        style={priceBoxWrapperStyle}
                        ref={childRef?.priceBoxRef}
                        className="priceBoxWrapper priceBoxWrapperRef"
                    >
                        <div className="price" data-testid={itemId ? `TESTING_TOTAL_PRICE_${itemId}` : ''}>
                            <div
                                ref={childRef?.financeLabelContainerRef}
                                className="financeLabel financeLabelContainerRef"
                            >
                                {isCataloguePriceEqualToStorePrice ? (
                                    <span className="priceLabel priceFromLabel">
                                        {t('offerListItem.standardPriceText')}
                                    </span>
                                ) : (
                                    <span className="priceLabel specialPriceLabel">
                                        {t('offerListItem.specialPriceText')}
                                    </span>
                                )}
                                <span className="priceFromLabel">{t('nameplate.priceFrom')}</span>
                            </div>
                            <div className={`price ${isShowPriceExcludedVAT ? 'showPriceExcludedVAT-container' : ''}`}>
                                <div className={`cashPrice ${isShowPriceExcludedVAT ? 'showPriceExcludedVAT' : ''}`} data-testid={`TESTING_CASH_PRICE_${itemId}`}>
                                    <span>{formatMoney(basePrice, true)}</span>
                                    <PriceSuffix checkPrioritizedTranslation hideAsterisk />
                                </div>
                                {isShowPriceExcludedVAT && (
                                    <PriceVatExcluded price={formatMoney(totalBasicPriceWithoutVat, true)} />
                                )}

                                {isFinancePriceAvailable && (
                                    <div>
                                        <div className="priceConjunction">
                                            <span>{t('offerListItem.financePrice.conjunction')}</span>
                                        </div>

                                        <div className="monthlyPrice" data-testid={`TESTING_FINANCE_PRICE_${itemId}`}>
                                            <span>{formatMoney(monthlyPrice, false, '', amountRounding)}</span>
                                            <PriceSuffix checkPrioritizedTranslation isMonthlyPrice />
                                            {showFOIcon && (
                                                <IconButton
                                                    className="infoIcon"
                                                    icon={
                                                        isBrandAC || isBrandAP || isBrandDS
                                                            ? Icons.InfoCircle
                                                            : Icons.Info
                                                    }
                                                    width={16}
                                                    height={16}
                                                    tabIndex={tabIndex}
                                                    onClick={calculateSummaryData}
                                                    dataTestId={itemId ? `TESTING_INFOICON_${itemId}` : ''}
                                                    altId={`svgImgSeeFinanceDetails-${index}`}
                                                    altText={financeDetailText}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {isFinancePriceAvailable && (
                            <div
                                className="priceNotice"
                                data-testid={itemId ? `TESTING_PRICE_LEGAL_TEXT_${itemId}` : ''}
                            >
                                {!isEmpty(promotionalText?.primaryText) ? (
                                    renderPromotionalText(
                                        promotionalText,
                                        t('price.pattern'),
                                        depositPrice,
                                        null,
                                        null,
                                        null,
                                        offerValidityDateString,
                                        { apr }
                                    )
                                ) : (
                                    <p className="aprValue">{`${apr} % ${t('landingPage.solCard.aprSuffix')}`}</p>
                                )}
                            </div>
                        )}

                        {!isShowPriceExcludedVAT && hasMonthlyPrices && showFOLink && (
                            <div className="apr">
                                <AccessibleButton className="aprDescription" onClick={calculateSummaryData}>
                                    {isMarketGB || financeDetailText === FINANCE_DETAIL_TOKEN
                                        ? t('landingPage.solCard.bonus.link')
                                        : parser.toReact(financeDetailText) ||
                                          t('financeOverlayButton.force.link.text')}
                                </AccessibleButton>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    } catch (e: any) {
        return <HandledComponentError error={e} />;
    }
};

export default PriceBoxTemplate;
