import React, { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterBoxProps } from './index';
import { FILTER_CATEGORY, isBrandOV, isMarketGB, ModalVersion } from '../../constants/main';
import { useTranslations } from '@hooks/useTranslations';
import { HandledComponentError } from '@components/ErrorHandling';
import { Redux } from '../../redux/redux.interface';
import FilterDuck from '../../redux/filters/filter.duck';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import { useRouter } from 'next/router';
import Routes from '../../constants/routes';
import UIDuck from '../../redux/commonDucks/ui.duck';
import { SEARCH_TYPES } from '@components/Modal/ModalPostalCode/ModalPostalCodeTemplate';
import DealerDuck from '../../redux/dealer/dealer.duck';
import FilterChip from '../../ui/FilterChip';
import {IPriceRange} from "../../redux/filters/filter.duck.interface";

const MAX_LENGTH_OF_SELECTED_FILTER_NAME = 20;

const FilterBoxTemplate: FC<FilterBoxProps> = ({ className, openFilter }) => {
    try {
        const { t } = useTranslations();

        const { formatMoney } = useMoneyFormatter();

        const { isStockJourney } = useCarStockJourneyCheck();

        const router = useRouter();

        const dispatch = useDispatch();

        const geoLocation = useSelector((state: Redux) => FilterDuck.getGeoLocation(state));
        const distanceRadius = useSelector((state: Redux) => FilterDuck.getDistanceRadius(state));
        const geoLocationName = useSelector((state: Redux) => FilterDuck.getGeoLocationName(state));

        const selectedDealer = useSelector((state: Redux) => DealerDuck.getOwnState(state).dealer);

        const allFilters = useSelector((state: Redux) => FilterDuck.getFilters(state));

        const selectedFilters = useMemo(() => allFilters.filter((filter) => !!filter.value), [allFilters]);

        const selectedPrices = useMemo(
            () => selectedFilters.filter((filter) => filter.parent === FILTER_CATEGORY.PRICES),
            [selectedFilters]
        );

        const selectedFuel = useMemo(
            () =>
                selectedFilters.filter((filter) =>
                    [FILTER_CATEGORY.FUEL, FILTER_CATEGORY.ENERGIES].includes(filter.parent as FILTER_CATEGORY)
                ),
            [selectedFilters]
        );

        const selectedGearbox = useMemo(
            () =>
                selectedFilters.filter((filter) =>
                    [FILTER_CATEGORY.GEARBOX, FILTER_CATEGORY.GEARBOX_TYPES].includes(filter.parent as FILTER_CATEGORY)
                ),
            [selectedFilters]
        );

        const selectedColors = useMemo(
            () => selectedFilters.filter((filter) => filter.parent === FILTER_CATEGORY.COLOR_GROUPS),
            [selectedFilters]
        );

        const selectedEngines = useMemo(
            () => selectedFilters.filter((filter) => (filter.parent as FILTER_CATEGORY) === FILTER_CATEGORY.ENGINES),
            [selectedFilters]
        );

        const selectedGrades = useMemo(
            () => selectedFilters.filter((filter) => (filter.parent as FILTER_CATEGORY) === FILTER_CATEGORY.GRADES),
            [selectedFilters]
        );

        const formattedDealerName = selectedDealer
            ? selectedDealer?.name?.length > MAX_LENGTH_OF_SELECTED_FILTER_NAME
                ? `${selectedDealer.name.substring(0, MAX_LENGTH_OF_SELECTED_FILTER_NAME)}...`
                : selectedDealer?.name
            : '';

        const formattedLocationName = geoLocationName
            ? geoLocationName?.length > MAX_LENGTH_OF_SELECTED_FILTER_NAME
                ? `${geoLocationName.substring(0, MAX_LENGTH_OF_SELECTED_FILTER_NAME)}...`
                : geoLocationName
            : '';

        const formattedDistance = `${distanceRadius} ${t('geoLocationInput.distanceRadiusOption')}`;

        const isTrimPage = router.pathname.includes(Routes.SELECTOR);

        return (
            <div className={className}>
                <div className="mobile">
                    {isStockJourney && (
                        <FilterChip
                            dataId="TESTING_FILTER_LOCATION"
                            customClassName={`${FILTER_CATEGORY.LOCATION} ${
                                geoLocation || selectedDealer?.externalId ? 'selected' : ''
                            }`}
                            onClick={() =>
                                dispatch(
                                    UIDuck.openModal({
                                        data: {
                                            searchType: selectedDealer?.externalId
                                                ? SEARCH_TYPES.DEALER
                                                : SEARCH_TYPES.LOCATION,
                                        },
                                        callbacks: {},
                                        modalType: UIDuck.MODAL_TYPES.POSTAL_CODE,
                                        modalVersion: ModalVersion.v2,
                                        modalProperties: {
                                            centerModeOnDesktop: true,
                                        },
                                    })
                                )
                            }
                            label={
                                geoLocation || selectedDealer?.externalId
                                    ? selectedDealer?.externalId
                                        ? formattedDealerName
                                        : `${formattedLocationName}${
                                              distanceRadius > 0 ? `, ${formattedDistance}` : ''
                                          }`
                                    : t('GeoLocationInput.label')
                            }
                        />
                    )}
                    <FilterChip
                        onClick={() => openFilter()}
                        label={t(`filters.filters`)}
                        countOfSelectedFilters={selectedFilters?.length}
                    />
                </div>
                <div className="desktop">
                    <div className="filters">{t(`filters.filters`)}</div>

                    <div className="selected">
                        {(geoLocation || selectedDealer?.externalId) && isStockJourney && (
                            <FilterChip
                                dataId="TESTING_FILTER_LOCATION"
                                customClassName={`${FILTER_CATEGORY.LOCATION} selected`}
                                onClick={() =>
                                    dispatch(
                                        UIDuck.openModal({
                                            data: {
                                                searchType: selectedDealer?.externalId
                                                    ? SEARCH_TYPES.DEALER
                                                    : SEARCH_TYPES.LOCATION,
                                            },
                                            callbacks: {},
                                            modalType: UIDuck.MODAL_TYPES.POSTAL_CODE,
                                            modalVersion: ModalVersion.v2,
                                            modalProperties: {
                                                centerModeOnDesktop: true,
                                            },
                                        })
                                    )
                                }
                                label={
                                    selectedDealer?.externalId
                                        ? formattedDealerName
                                        : `${formattedLocationName}${
                                              distanceRadius > 0 ? `, ${formattedDistance}` : ''
                                          }`
                                }
                            />
                        )}
                        {[
                            { category: FILTER_CATEGORY.FUEL, values: selectedFuel },
                            { category: FILTER_CATEGORY.GEARBOX, values: selectedGearbox },
                            { category: FILTER_CATEGORY.PRICES, values: selectedPrices },
                            { category: FILTER_CATEGORY.ENGINES, values: selectedEngines },
                            { category: FILTER_CATEGORY.GRADES, values: selectedGrades },
                            { category: FILTER_CATEGORY.COLOR, values: selectedColors },
                        ].map(
                            (category, index) =>
                                category.values?.length > 0 &&
                                (category.category === FILTER_CATEGORY.PRICES ? (
                                    <FilterChip
                                        dataId="TESTING_FILTER_PRICES"
                                        customClassName={`${category.category} selected`}
                                        index={index}
                                        onClick={() => openFilter(category.category)}
                                        minPrice={(category.values[0].value as IPriceRange).min}
                                        maxPrice={(category.values[0].value as IPriceRange).max}
                                    />
                                ) : (
                                    <FilterChip
                                        dataId={`TESTING_FILTER_${category.category.toUpperCase()}`}
                                        customClassName={`${category.category} selected`}
                                        index={index}
                                        onClick={() => openFilter(category.category)}
                                        label={`${t(category.values[0].displayName)}${
                                            category.values?.length > 1 ? `,+${category.values?.length - 1}` : ''
                                        }`}
                                    />
                                ))
                        )}
                    </div>

                    {isStockJourney && !geoLocation && !selectedDealer?.externalId && (
                        <FilterChip
                            dataId="TESTING_FILTER_LOCATION"
                            customClassName={FILTER_CATEGORY.LOCATION}
                            onClick={() =>
                                dispatch(
                                    UIDuck.openModal({
                                        data: { data: {} },
                                        callbacks: {},
                                        modalType: UIDuck.MODAL_TYPES.POSTAL_CODE,
                                        modalVersion: ModalVersion.v2,
                                        modalProperties: {
                                            centerModeOnDesktop: true,
                                        },
                                    })
                                )
                            }
                            label={t('GeoLocationInput.label')}
                        />
                    )}

                    {selectedFuel?.length === 0 && (
                        <FilterChip
                            dataId={`TESTING_FILTER_${FILTER_CATEGORY.FUEL.toUpperCase()}`}
                            customClassName={FILTER_CATEGORY.FUEL}
                            onClick={() => openFilter(FILTER_CATEGORY.FUEL)}
                            label={t('filters.category.fuel')}
                        />
                    )}

                    {((!isBrandOV || !isMarketGB) || isStockJourney) && selectedGearbox?.length === 0 && (
                        <>
                            <FilterChip
                                dataId={`TESTING_FILTER_${FILTER_CATEGORY.GEARBOX.toUpperCase()}`}
                                customClassName={FILTER_CATEGORY.GEARBOX}
                                onClick={() => openFilter(FILTER_CATEGORY.GEARBOX)}
                                label={t('filters.category.gearbox')}
                            />
                        </>
                    )}

                    {selectedPrices?.length === 0 && (
                        <FilterChip
                            dataId="TESTING_FILTER_PRICES"
                            customClassName={FILTER_CATEGORY.PRICES}
                            onClick={() => openFilter(FILTER_CATEGORY.PRICES)}
                            label={t('filters.category.budget')}
                        />
                    )}

                    {isStockJourney && isTrimPage && selectedEngines?.length === 0 && (
                        <FilterChip
                            dataId={`TESTING_FILTER_${FILTER_CATEGORY.ENGINES.toUpperCase()}`}
                            customClassName={FILTER_CATEGORY.ENGINES}
                            onClick={() => openFilter(FILTER_CATEGORY.ENGINES)}
                            label={t('filters.category.engines')}
                        />
                    )}

                    {isStockJourney && isTrimPage && selectedGrades?.length === 0 && (
                        <FilterChip
                            dataId={`TESTING_FILTER_${FILTER_CATEGORY.GRADES.toUpperCase()}`}
                            customClassName={FILTER_CATEGORY.GRADES}
                            onClick={() => openFilter(FILTER_CATEGORY.GRADES)}
                            label={t('filters.category.grades')}
                        />
                    )}

                    {isStockJourney && isTrimPage && selectedColors?.length === 0 && (
                        <FilterChip
                            dataId={`TESTING_FILTER_${FILTER_CATEGORY.COLOR.toUpperCase()}`}
                            customClassName={FILTER_CATEGORY.COLOR}
                            onClick={() => openFilter(FILTER_CATEGORY.COLOR)}
                            label={t('filters.category.colorGroups')}
                        />
                    )}
                </div>
            </div>
        );
    } catch (e: any) {
        return <HandledComponentError error={e} />;
    }
};

export default FilterBoxTemplate;
