import styled from 'styled-components';
import { PriceBoxStyled } from '../PriceBoxStyled';
import { rem } from 'polished';

export const PriceBoxStyledOV = styled(PriceBoxStyled)`
    background-color: ${({ theme }) => theme.colors.grey20};

    .catalogPrice {
        font-size: ${({ theme }) => theme.fontSizes.textSupBase};
        background-color: ${({ theme }) => theme.colors.pureWhite};
        .catalogPriceLabel,
        .catalogPriceAmount {
            font-size: ${({ theme }) => theme.fontSizes.textSupBase};
        }
    }
    .priceBoxWrapper {
        background-color: ${({ theme }) => theme.colors.grey20};

        .priceNotice {
            font-size: ${({ theme }) => theme.fontSizes.textSupBase};
        }

        .financeLabel {
            color: ${({ theme }) => theme.colors.grey22};

            .priceLabel {
                font-size: ${({ theme }) => theme.fontSizes.textSupBase};
                font-weight: bold;
            }
            .priceFromLabel {
                :not(.priceLabel) {
                    font-size: ${({ theme }) => theme.fontSizes.textSupBase};
                    font-weight: normal;
                }
            }
        }

        .price {
            font-weight: normal;

            .cashPrice,
            .cashPriceWithoutTax {
                font-size: ${rem('18px')};
                > span:first-child > span {
                    font-weight: bold;
                }
            }
            .monthlyPrice {
                font-size: ${rem('18px')};
                span {
                    font-weight: normal;
                }
                .infoIcon {
                    svg circle {
                        stroke: transparent;
                        fill: transparent;
                    }
                }
            }
            .priceConjunction {
                span {
                    font-weight: normal;
                    font-size: ${rem(12)};
                    line-height: ${rem(18)};
                }
            }
            .currencyLabel__suffix--TTC,
            .currencyLabel__suffix--TTC-monthly,
            .currencyLabel__suffix--OTR {
                font-size: ${rem('14px')};
                font-weight: normal;
            }
        }
    }
}
`;
