import React from 'react';
import { BRAND } from '../../constants/main';
import { PriceLegalTextStyledAC } from './AC/PriceLegalTextStyled.AC';
import { PriceLegalTextStyledAP } from './AP/PriceLegalTextStyled.AP';
import { PriceLegalTextStyledDS } from './DS/PriceLegalTextStyled.DS';
import { PriceLegalTextStyledOV } from './OV/PriceLegalTextStyled.OV';
import { PriceLegalTextStyled } from './PriceLegalTextStyled';
import { SC } from '../../styles/theme';

export interface PriceLegalTextProps extends SC {
    isStockJourney?: boolean;
}

const PriceLegalText = (props: PriceLegalTextProps) => {
    if (BRAND === 'AC') return <PriceLegalTextStyledAC {...props} />;
    if (BRAND === 'OV') return <PriceLegalTextStyledOV {...props} />;
    if (BRAND === 'AP') return <PriceLegalTextStyledAP {...props} />;
    if (BRAND === 'DS') return <PriceLegalTextStyledDS {...props} />;
    return <PriceLegalTextStyled {...props} />;
};

export default PriceLegalText;
