import styled from 'styled-components';
import { breakpoints, SC } from '../../styles/theme';
import FilterBoxTemplate from './FilterBoxTemplate';
import { rem } from 'polished';
import { FILTER_CATEGORY, isBrandAC, isBrandAP, isBrandDS, isBrandOV, isMarketGB } from '../../constants/main';
import { StylesOV } from './Styles.OV';
import { StylesOVGB } from './Styles.OVGB';
import { StylesDS } from './Styles.DS';
import { StylesAP } from './Styles.AP';
import { StylesAC } from './Styles.AC';

export interface FilterBoxProps extends SC {
    openFilter: (category?: FILTER_CATEGORY) => void;
}

export const FilterBox = styled(FilterBoxTemplate)`
    background: ${({ theme }) => theme.colors.white};
    padding: ${rem(16)};
    position: sticky;
    top: ${({ isUserLoggedInAsDealer }) => (isUserLoggedInAsDealer ? rem(36) : 0)};
    z-index: 99999;
    width: 100%;

    .mobile {
        @media (min-width: ${breakpoints.sm}px) {
            display: none;
        }
    }

    .desktop {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        display: none;

        @media (min-width: ${breakpoints.sm}px) {
            display: flex;
        }

        @media only screen and (min-width: 75em) {
            max-width: 92rem !important;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .filters {
        color: ${({ theme }) => theme.colors.grey5};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        padding-right: ${rem(16)};
    }

    ${isBrandAC && StylesAC};
    ${isBrandAP && StylesAP};
    ${isBrandDS && StylesDS};
    ${isBrandOV && (isMarketGB ? StylesOVGB : StylesOV)}
`;

export default FilterBox;
