import styled from 'styled-components';
import { breakpoints } from '../../../../styles/theme';
import { PriceBoxStyled } from '../PriceBoxStyled';
import { rem } from 'polished';

export const PriceBoxStyledDS = styled(PriceBoxStyled)`
    background: ${({ theme }) => theme.colors.grey4};
    border-radius: ${rem(8)};
    margin-top: ${rem(30)};
    position: relative;
    height: calc(100% - 30px);

    .priceBoxContainerContentWrapper {
        height: 100%;
        overflow: hidden;
        border-radius: 0.5rem;
    }

    .catalogPrice {
        color: ${({ theme }) => theme.colors.black};
        position: absolute;
        height: ${rem(30)};
        width: 100%;
        top: -${rem(30)};

        .catalogPriceLabel {
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            font-size: ${({ theme }) => theme.fontSizes.textSupBase};
        }
        .catalogPriceAmount {
            font-size: ${({ theme }) => theme.fontSizes.textSupBase};
            font-weight: ${({ theme }) => theme.fontWeights.textExtraBold};
        }

        .currencyLabel__suffix--OTR  {
            font-size: ${({ theme }) => theme.fontSizes.textSupBase};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }
    }

    .priceBoxWrapper {
        height: 100%;
        .financeLabel  {
            font-size: ${({ theme }) => theme.fontSizes.h6};
            color: ${({ theme }) => theme.colors.black};
        }

        .aprValue {
            color: ${({ theme }) => theme.colors.black};
        }

        .aprDescription {
            text-decoration: underline;
            margin-top: ${rem(5)};
        }

        .price {
            .cashPrice,
            .monthlyPrice {
                span {
                    color: ${({ theme }) => theme.colors.black};
                }
            }

            .cashPrice,
            .cashPriceWithoutTax {
                font-size: ${rem(24)} !important;

                @media (min-width: ${breakpoints.sm}px) {
                    font-size: ${rem(20)} !important;
                }
            }

            .monthlyPrice {
                font-size: ${rem(24)} !important;

                @media (min-width: ${breakpoints.sm}px) {
                    font-size: ${rem(20)} !important;
                }
            }

            .priceConjunction {
                span {
                    font-size: ${({ theme }) => theme.fontSizes.h6};
                }
            }

            .currencyLabel__suffix--OTR,
            .currencyLabel__suffix--TTC {
                padding-left: ${rem(2)};
                font-size: ${({ theme }) => theme.fontSizes.h5};
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            }

            .currencyLabel__suffix--TTC-monthly {
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                font-size: ${({ theme }) => theme.fontSizes.h5};
                text-transform: lowercase;
            }
        }

        .priceNotice {
            font-size: ${({ theme }) => theme.fontSizes.h6};

            .amount {
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
            }

            .promotionalText {
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            }

            .promotionalText {
                line-height: ${rem(18)};
            }
        }
    }
`;
