import React from 'react';
import { PriceBoxStyledAC } from './AC/PriceBoxStyled.AC';
import { PriceBoxStyledAP } from './AP/PriceBoxStyled.AP';
import { PriceBoxStyledDS } from './DS/PriceBoxStyled.DS';
import { PriceBoxStyledOV } from './OV/PriceBoxStyled.OV';
import { PriceBoxStyledOVGB } from './OV/PriceBoxStyled.OVGB';
import { PriceBoxStyledOVES } from './OV/PriceBoxStyled.OVES';
import { PriceBoxStyled } from './PriceBoxStyled';
import { SC } from 'src/styles/theme';
import { BRAND, isMarketGB, isMarketES } from 'src/constants/main';
import { BrandTypes } from 'src/types/brands';
import { IPromotionalText } from 'src/interfaces/Price';
import { PriceRounding } from 'src/types/priceRounding';
import { ICarImportInput } from 'src/interfaces/Car';

export interface PriceBoxDetailsProps {
    basePrice: number;
    totalBasicPriceWithoutVat?: number;
    catalogPrice?: number;
    financeDetailView?: string;
    monthlyPrice: number;
    isStockSlice?: boolean;
}

// export type PriceBoxProps = SC;
export interface PriceBoxProps extends SC, PriceBoxDetailsProps {
    calculateSummaryData: () => void;
    depositPrice: number;
    isHomePage?: boolean;
    isStockList?: boolean;
    promotionalText?: IPromotionalText;
    amountRounding?: PriceRounding;
    itemId?: string;
    priceBoxWrapperStyle?: object;
    financeDetailText?: string;
    apr?: number;
    hasMonthlyPrices?: boolean;
    childRef?: {
        priceBoxRef?: React.Ref<HTMLDivElement>;
        priceBoxCatalogRef?: React.Ref<HTMLDivElement>;
        financeLabelContainerRef?: React.Ref<HTMLDivElement>;
        priceBoxContainerRef?: React.Ref<HTMLDivElement>;
    };
    importInputs?: ICarImportInput;
    tabIndex?: number;
    index?: number;
}

const PriceBox = (props: PriceBoxProps) => {
    if (BRAND === BrandTypes.AC) return <PriceBoxStyledAC {...props} />;
    if (BRAND === BrandTypes.OV) {
        if (isMarketGB) return <PriceBoxStyledOVGB {...props} />;
        if (isMarketES) return <PriceBoxStyledOVES {...props} />;
        return <PriceBoxStyledOV {...props} />;
    }
    if (BRAND === BrandTypes.AP) return <PriceBoxStyledAP {...props} />;
    if (BRAND === BrandTypes.DS) return <PriceBoxStyledDS {...props} />;
    return <PriceBoxStyled {...props} />;
};

export default PriceBox;
