import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { PriceLegalTextStyled } from '../PriceLegalTextStyled';
import { rem } from 'polished';

export const PriceLegalTextStyledAC = styled(PriceLegalTextStyled)`
    margin: 0;

    @media (min-width: ${breakpoints.xs}px) {
        margin: ${rem('10px')} ${({ isStockJourney }) => (isStockJourney ? 0 : rem('16px'))} ${rem('0px')};
    }

    .legalText {
        color: ${({ theme }) => theme.colors.grey1};
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-size: ${rem('12px')};
        text-align: left;
    }
`;
