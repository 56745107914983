import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { PriceLegalTextStyled } from '../PriceLegalTextStyled';
import { rem } from 'polished';

export const PriceLegalTextStyledAP = styled(PriceLegalTextStyled)`
    margin: 0;

    @media (min-width: ${breakpoints.xs}px) {
        margin: ${rem('10px')} ${({ isStockJourney }) => (isStockJourney ? 0 : rem('16px'))} ${rem('0px')};
    }

    .legalText {
        color: ${props => props.theme.colors.grey6};
        font-size: ${rem('10px')};
        line-height: 1.5;
    }
`;
