import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST, IFinanceWidgetButtonType } from 'src/context/featureSwitchApp';
import { FINANCE_DETAIL_VIEW } from 'src/constants/main';
import { PriceBoxDetailsProps } from '@components/PriceBox/Configurable';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import { useIsShowPriceExcludedVAT } from '@hooks/useIsShowPriceExcludedVAT';

export const usePriceBoxDetails = ({
    catalogPrice,
    basePrice,
    financeDetailView,
    monthlyPrice,
    isStockSlice,
}: PriceBoxDetailsProps) => {
    const isCataloguePriceEqualToStorePrice = catalogPrice && catalogPrice === basePrice;
    const { isStockJourney } = useCarStockJourneyCheck();
    const isShowPriceExcludedVAT: boolean = useIsShowPriceExcludedVAT();
    const financePriceAvailable = monthlyPrice || monthlyPrice === 0;
    const isFinancePriceAvailable =
        isStockSlice || isStockJourney ? financePriceAvailable : !isShowPriceExcludedVAT && financePriceAvailable;

    const isFinanceOverlayForcedToLink: boolean = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_FORCE_FINANCE_WIDGET_BUTTON,
        IFinanceWidgetButtonType.LINK
    );

    const isFinanceOverlayForcedToIcon: boolean = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_FORCE_FINANCE_WIDGET_BUTTON,
        IFinanceWidgetButtonType.ICON
    );
    const showFOIcon =
        (financeDetailView === FINANCE_DETAIL_VIEW.ICON || isFinanceOverlayForcedToIcon) &&
        !isFinanceOverlayForcedToLink;

    const showFOLink =
        (financeDetailView === FINANCE_DETAIL_VIEW.LINK || isFinanceOverlayForcedToLink) &&
        !isFinanceOverlayForcedToIcon;

    const isCataloguePriceFeatureSwitchEnabled = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_DISPLAY_CATALOGUE_PRICE
    );

    return {
        isCataloguePriceEqualToStorePrice,
        isFinanceOverlayForcedToIcon,
        isCataloguePriceFeatureSwitchEnabled,
        isFinancePriceAvailable,
        showFOIcon,
        showFOLink,
    };
};
